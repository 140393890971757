import { Country } from './Country';
import { Currency } from './Currency';

export const countryCurrencies: Record<Country, Currency> = {
    [Country.Andorra]: Currency.EUR,
    [Country.UnitedArabEmirates]: Currency.AED,
    [Country.Afghanistan]: Currency.AFN,
    [Country.AntiguaAndBarbuda]: Currency.XCD,
    [Country.Anguilla]: Currency.XCD,
    [Country.Albania]: Currency.ALL,
    [Country.Armenia]: Currency.AMD,
    [Country.Angola]: Currency.AOA,
    [Country.Argentina]: Currency.ARS,
    [Country.AmericanSamoa]: Currency.USD,
    [Country.Austria]: Currency.EUR,
    [Country.Australia]: Currency.AUD,
    [Country.Aruba]: Currency.AWG,
    [Country.AlandIslands]: Currency.EUR,
    [Country.Azerbaijan]: Currency.AZN,
    [Country.BosniaAndHerzegovina]: Currency.BAM,
    [Country.Barbados]: Currency.USD,
    [Country.Bangladesh]: Currency.BDT,
    [Country.Belgium]: Currency.EUR,
    [Country.BurkinaFaso]: Currency.XOF,
    [Country.Bulgaria]: Currency.BGN,
    [Country.Bahrain]: Currency.BHD,
    [Country.Burundi]: Currency.BIF,
    [Country.Benin]: Currency.XOF,
    [Country.SaintBarthelemy]: Currency.EUR,
    [Country.Bermuda]: Currency.BMD,
    [Country.BruneiDarussalam]: Currency.SGD,
    [Country.Bolivia]: Currency.BOB,
    [Country.Bonaire]: Currency.USD,
    [Country.Brazil]: Currency.BRL,
    [Country.Bahamas]: Currency.BSD,
    [Country.Bhutan]: Currency.BTN,
    [Country.Botswana]: Currency.BWP,
    [Country.Belarus]: Currency.BYN,
    [Country.Belize]: Currency.BZD,
    [Country.Canada]: Currency.CAD,
    [Country.CanaryIslands]: Currency.EUR,
    [Country.DemocraticRepublicOfTheCongo]: Currency.CDF,
    [Country.CentralAfricanRepublic]: Currency.XAF,
    [Country.RepublicOfTheCongo]: Currency.XAF,
    [Country.Switzerland]: Currency.CHF,
    [Country.CoteDivoire]: Currency.XOF,
    [Country.CookIslands]: Currency.NZD,
    [Country.Chile]: Currency.CLP,
    [Country.Cameroon]: Currency.XAF,
    [Country.China]: Currency.CNY,
    [Country.Colombia]: Currency.COP,
    [Country.CostaRica]: Currency.CRC,
    [Country.Cuba]: Currency.CUC,
    [Country.CaboVerde]: Currency.CVE,
    [Country.Curacao]: Currency.ANG,
    [Country.Cyprus]: Currency.EUR,
    [Country.CzechRepublic]: Currency.CZK,
    [Country.Germany]: Currency.EUR,
    [Country.Djibouti]: Currency.DJF,
    [Country.Denmark]: Currency.DKK,
    [Country.Dominica]: Currency.XCD,
    [Country.DominicanRepublic]: Currency.DOP,
    [Country.Algeria]: Currency.DZD,
    [Country.Ecuador]: Currency.USD,
    [Country.Estonia]: Currency.EUR,
    [Country.Egypt]: Currency.EGP,
    [Country.WesternSahara]: Currency.MAD,
    [Country.Eritrea]: Currency.ERN,
    [Country.Spain]: Currency.EUR,
    [Country.Ethiopia]: Currency.ETB,
    [Country.Finland]: Currency.EUR,
    [Country.Fiji]: Currency.FJD,
    [Country.FalklandIslands]: Currency.FKP,
    [Country.FederatedStatesOfMicronesia]: Currency.USD,
    [Country.FaroeIslands]: Currency.DKK,
    [Country.France]: Currency.EUR,
    [Country.Gabon]: Currency.XAF,
    [Country.UnitedKingdom]: Currency.GBP,
    [Country.Grenada]: Currency.XCD,
    [Country.Georgia]: Currency.GEL,
    [Country.FrenchGuiana]: Currency.EUR,
    [Country.Guernsey]: Currency.GBP,
    [Country.Ghana]: Currency.GHS,
    [Country.Gibraltar]: Currency.GIP,
    [Country.Greenland]: Currency.DKK,
    [Country.Gambia]: Currency.GMD,
    [Country.Guinea]: Currency.GNF,
    [Country.Guadeloupe]: Currency.EUR,
    [Country.EquatorialGuinea]: Currency.XAF,
    [Country.Greece]: Currency.EUR,
    [Country.Guatemala]: Currency.GTQ,
    [Country.Guam]: Currency.USD,
    [Country.GuineaBissau]: Currency.XOF,
    [Country.Guyana]: Currency.GYD,
    [Country.HongKong]: Currency.HKD,
    [Country.Honduras]: Currency.HNL,
    [Country.Croatia]: Currency.HRK,
    [Country.Haiti]: Currency.HTG,
    [Country.Hungary]: Currency.HUF,
    [Country.Indonesia]: Currency.IDR,
    [Country.Ireland]: Currency.EUR,
    [Country.Israel]: Currency.ILS,
    [Country.IsleOfMan]: Currency.GBP,
    [Country.India]: Currency.INR,
    [Country.Iraq]: Currency.IQD,
    [Country.Iran]: Currency.IRR,
    [Country.Iceland]: Currency.ISK,
    [Country.Italy]: Currency.EUR,
    [Country.Jersey]: Currency.GBP,
    [Country.Jamaica]: Currency.JMD,
    [Country.Jordan]: Currency.JOD,
    [Country.Japan]: Currency.JPY,
    [Country.Kenya]: Currency.KES,
    [Country.Kyrgyzstan]: Currency.KGS,
    [Country.Cambodia]: Currency.KHR,
    [Country.Kiribati]: Currency.AUD,
    [Country.Comoros]: Currency.KMF,
    [Country.SaintKittsAndNevis]: Currency.XCD,
    [Country.NorthKorea]: Currency.KPW,
    [Country.SouthKorea]: Currency.KRW,
    [Country.Kosovo]: Currency.WST,
    [Country.Kuwait]: Currency.KWD,
    [Country.CaymanIslands]: Currency.KYD,
    [Country.Kazakhstan]: Currency.KZT,
    [Country.Laos]: Currency.LAK,
    [Country.Lebanon]: Currency.LBP,
    [Country.SaintLucia]: Currency.XCD,
    [Country.Liechtenstein]: Currency.CHF,
    [Country.SriLanka]: Currency.LKR,
    [Country.Liberia]: Currency.LRD,
    [Country.Lesotho]: Currency.LSL,
    [Country.Lithuania]: Currency.LTL,
    [Country.Luxembourg]: Currency.EUR,
    [Country.Latvia]: Currency.EUR,
    [Country.Libya]: Currency.LYD,
    [Country.Morocco]: Currency.MAD,
    [Country.Monaco]: Currency.EUR,
    [Country.Moldova]: Currency.MDL,
    [Country.Montenegro]: Currency.EUR,
    [Country.SaintMartin]: Currency.EUR,
    [Country.Madagascar]: Currency.MGA,
    [Country.MarshallIslands]: Currency.USD,
    [Country.NorthMacedonia]: Currency.MKD,
    [Country.Mali]: Currency.XOF,
    [Country.Myanmar]: Currency.MMK,
    [Country.Mongolia]: Currency.MNT,
    [Country.Macau]: Currency.HKD,
    [Country.NorthernMarianaIslands]: Currency.USD,
    [Country.Martinique]: Currency.EUR,
    [Country.Mauritania]: Currency.MRU,
    [Country.Montserrat]: Currency.XCD,
    [Country.Malta]: Currency.EUR,
    [Country.Mauritius]: Currency.MUR,
    [Country.Maldives]: Currency.MVR,
    [Country.Malawi]: Currency.MWK,
    [Country.Mexico]: Currency.MXN,
    [Country.Malaysia]: Currency.MYR,
    [Country.Mozambique]: Currency.MZN,
    [Country.Namibia]: Currency.NAD,
    [Country.NewCaledonia]: Currency.XPF,
    [Country.Niger]: Currency.NZD,
    [Country.NorfolkIsland]: Currency.AUD,
    [Country.Nigeria]: Currency.NGN,
    [Country.Nicaragua]: Currency.NIO,
    [Country.Netherlands]: Currency.EUR,
    [Country.Norway]: Currency.NOK,
    [Country.Nepal]: Currency.NPR,
    [Country.Nauru]: Currency.AUD,
    [Country.Niue]: Currency.NZD,
    [Country.NewZealand]: Currency.NZD,
    [Country.Oman]: Currency.OMR,
    [Country.Panama]: Currency.PAB,
    [Country.Peru]: Currency.PEN,
    [Country.FrenchPolynesia]: Currency.XPF,
    [Country.PapuaNewGuinea]: Currency.PGK,
    [Country.Philippines]: Currency.PHP,
    [Country.Pakistan]: Currency.PKR,
    [Country.Poland]: Currency.PLN,
    [Country.SaintPierreAndMiquelon]: Currency.EUR,
    [Country.PuertoRico]: Currency.USD,
    [Country.StateOfPalestine]: Currency.ILS,
    [Country.Portugal]: Currency.EUR,
    [Country.Palau]: Currency.USD,
    [Country.Paraguay]: Currency.PYG,
    [Country.Qatar]: Currency.QAR,
    [Country.Reunion]: Currency.EUR,
    [Country.Romania]: Currency.RON,
    [Country.Serbia]: Currency.RSD,
    [Country.Russia]: Currency.RUB,
    [Country.Rwanda]: Currency.RWF,
    [Country.SaudiArabia]: Currency.SAR,
    [Country.SolomonIslands]: Currency.SBD,
    [Country.Seychelles]: Currency.SCR,
    [Country.Sudan]: Currency.SDG,
    [Country.Sweden]: Currency.SEK,
    [Country.Singapore]: Currency.SGD,
    [Country.SaintHelenaAscensionAndTristanDaCunha]: Currency.SHP,
    [Country.Slovenia]: Currency.EUR,
    [Country.Slovakia]: Currency.EUR,
    [Country.SierraLeone]: Currency.SLL,
    [Country.SanMarino]: Currency.EUR,
    [Country.Senegal]: Currency.XOF,
    [Country.Somalia]: Currency.SOS,
    [Country.Suriname]: Currency.SRD,
    [Country.SouthSudan]: Currency.SSP,
    [Country.SaoTomeAndPrincipe]: Currency.STD,
    [Country.ElSalvador]: Currency.NOK,
    [Country.SintMaarten]: Currency.ANG,
    [Country.SyrianArabRepublic]: Currency.SYP,
    [Country.Swaziland]: Currency.SZL,
    [Country.TurksAndCaicosIslands]: Currency.USD,
    [Country.Chad]: Currency.XAF,
    [Country.Togo]: Currency.XOF,
    [Country.Thailand]: Currency.THB,
    [Country.Tajikistan]: Currency.TJS,
    [Country.Tokelau]: Currency.NZD,
    [Country.TimorLeste]: Currency.NZD,
    [Country.Turkmenistan]: Currency.TMT,
    [Country.Tunisia]: Currency.TND,
    [Country.Tonga]: Currency.TOP,
    [Country.Turkey]: Currency.TRY,
    [Country.TrinidadAndTobago]: Currency.TTD,
    [Country.Tuvalu]: Currency.AUD,
    [Country.Taiwan]: Currency.TWD,
    [Country.Tanzania]: Currency.TZS,
    [Country.Ukraine]: Currency.UAH,
    [Country.Uganda]: Currency.UGX,
    [Country.UnitedStates]: Currency.USD,
    [Country.Uruguay]: Currency.UYU,
    [Country.Uzbekistan]: Currency.UZS,
    [Country.HolySee]: Currency.EUR,
    [Country.SaintVincentAndTheGrenadines]: Currency.XCD,
    [Country.Venezuela]: Currency.VEF,
    [Country.VirginIslandsBritish]: Currency.USD,
    [Country.VirginIslandsUs]: Currency.USD,
    [Country.Vietnam]: Currency.VND,
    [Country.Vanuatu]: Currency.VUV,
    [Country.WallisAndFutuna]: Currency.XPF,
    [Country.Samoa]: Currency.WST,
    [Country.Yemen]: Currency.YER,
    [Country.Mayotte]: Currency.EUR,
    [Country.SouthAfrica]: Currency.ZAR,
    [Country.Zambia]: Currency.ZMW,
    [Country.Zimbabwe]: Currency.ZWD,
};
