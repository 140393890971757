import { invert, orderBy, values } from 'lodash';

import { Country } from './Country';
import { Phone } from './Phone';

const CANADA_AREA_CODES = [
    '204',
    '236',
    '249',
    '250',
    '289',
    '306',
    '343',
    '365',
    '367',
    '403',
    '416',
    '418',
    '431',
    '437',
    '438',
    '450',
    '506',
    '514',
    '519',
    '548',
    '579',
    '581',
    '587',
    '604',
    '613',
    '639',
    '647',
    '672',
    '705',
    '709',
    '778',
    '780',
    '782',
    '807',
    '819',
    '825',
    '867',
    '873',
    '902',
    '905',
];

const CANARY_ISLANDS_AREA_CODES = ['822', '828', '922', '928'];

export const countryDialCodes: Record<Country, string> = {
    [Country.Afghanistan]: '+93',
    [Country.Anguilla]: '+1264',
    [Country.AmericanSamoa]: '+1684',
    [Country.Aruba]: '+297',
    [Country.AlandIslands]: '+358',
    [Country.Albania]: '+355',
    [Country.Algeria]: '+213',
    [Country.Andorra]: '+376',
    [Country.Angola]: '+244',
    [Country.AntiguaAndBarbuda]: '+1268',
    [Country.Argentina]: '+54',
    [Country.Armenia]: '+374',
    [Country.Australia]: '+61',
    [Country.Austria]: '+43',
    [Country.Azerbaijan]: '+994',
    [Country.Bahamas]: '+1242',
    [Country.Bahrain]: '+973',
    [Country.Bangladesh]: '+880',
    [Country.Barbados]: '+1246',
    [Country.Belarus]: '+375',
    [Country.FalklandIslands]: '+500',
    [Country.FederatedStatesOfMicronesia]: '+691',
    [Country.Gibraltar]: '+350',
    [Country.FrenchGuiana]: '+594',
    [Country.FaroeIslands]: '+298',
    [Country.Belgium]: '+32',
    [Country.Belize]: '+501',
    [Country.HolySee]: '+379',
    [Country.Benin]: '+229',
    [Country.Bhutan]: '+975',
    [Country.Bolivia]: '+591',
    [Country.SaintBarthelemy]: '+590',
    [Country.Greenland]: '+299',
    [Country.Guadeloupe]: '+590',
    [Country.Guam]: '+1671',
    [Country.TurksAndCaicosIslands]: '+1649',
    [Country.Tokelau]: '+690',
    [Country.BosniaAndHerzegovina]: '+387',
    [Country.Kosovo]: '+383',
    [Country.VirginIslandsUs]: '+1340',
    [Country.WallisAndFutuna]: '+681',
    [Country.Mayotte]: '+262',
    [Country.Niue]: '+683',
    [Country.VirginIslandsBritish]: '+1284',
    [Country.SyrianArabRepublic]: '+963',
    [Country.SaintHelenaAscensionAndTristanDaCunha]: '+290',
    [Country.SintMaarten]: '+721',
    [Country.PuertoRico]: '+1787',
    [Country.Reunion]: '+262',
    [Country.StateOfPalestine]: '+970',
    [Country.SaintPierreAndMiquelon]: '+508',
    [Country.FrenchPolynesia]: '689',
    [Country.NorfolkIsland]: '+672',
    [Country.NewCaledonia]: '+687',
    [Country.CaymanIslands]: '+1855',
    [Country.Bermuda]: '+1441',
    [Country.HongKong]: '+852',
    [Country.IsleOfMan]: '+441624',
    [Country.Jersey]: '+441534',
    [Country.BruneiDarussalam]: '+673',
    [Country.Botswana]: '+267',
    [Country.Brazil]: '+55',
    [Country.Bonaire]: '+599',
    [Country.Bulgaria]: '+359',
    [Country.BurkinaFaso]: '+226',
    [Country.SaintMartin]: '+590',
    [Country.NorthernMarianaIslands]: '+1670',
    [Country.Montserrat]: '+1664',
    [Country.Martinique]: '+596',
    [Country.Macau]: '+853',
    [Country.Burundi]: '+257',
    [Country.DemocraticRepublicOfTheCongo]: '+243',
    [Country.RepublicOfTheCongo]: '+242',
    [Country.CaboVerde]: '+238',
    [Country.Cambodia]: '+855',
    [Country.Cameroon]: '+237',
    [Country.Curacao]: '+559',
    [Country.CoteDivoire]: '+225',
    [Country.CookIslands]: '+682',
    [Country.Canada]: '+1',
    [Country.CanaryIslands]: '+34',
    [Country.CentralAfricanRepublic]: '+236',
    [Country.Chad]: '+235',
    [Country.Chile]: '+56',
    [Country.China]: '+86',
    [Country.Colombia]: '+57',
    [Country.Comoros]: '+269',
    [Country.CostaRica]: '+506',
    [Country.Croatia]: '+385',
    [Country.Cuba]: '+53',
    [Country.Cyprus]: '+357',
    [Country.CzechRepublic]: '+420',
    [Country.Denmark]: '+45',
    [Country.Djibouti]: '+253',
    [Country.WesternSahara]: '+212',
    [Country.Dominica]: '+1767',
    [Country.DominicanRepublic]: '+1809',
    [Country.Ecuador]: '+593',
    [Country.Egypt]: '+20',
    [Country.ElSalvador]: '+503',
    [Country.EquatorialGuinea]: '+240',
    [Country.Eritrea]: '+291',
    [Country.Estonia]: '+372',
    [Country.Ethiopia]: '+251',
    [Country.Fiji]: '+679',
    [Country.Finland]: '+358',
    [Country.France]: '+33',
    [Country.Gabon]: '+241',
    [Country.Gambia]: '+220',
    [Country.Georgia]: '+995',
    [Country.Germany]: '+49',
    [Country.Ghana]: '+233',
    [Country.Greece]: '+30',
    [Country.Grenada]: '+1473',
    [Country.Guatemala]: '+502',
    [Country.Guinea]: '+224',
    [Country.GuineaBissau]: '+245',
    [Country.Guyana]: '+592',
    [Country.Haiti]: '+509',
    [Country.Honduras]: '+504',
    [Country.Hungary]: '+36',
    [Country.Iceland]: '+354',
    [Country.India]: '+91',
    [Country.Indonesia]: '+62',
    [Country.Iran]: '+98',
    [Country.Iraq]: '+964',
    [Country.Ireland]: '+353',
    [Country.Israel]: '+972',
    [Country.Italy]: '+39',
    [Country.Jamaica]: '+1876',
    [Country.Japan]: '+81',
    [Country.Jordan]: '+962',
    [Country.Kazakhstan]: '+7',
    [Country.Kenya]: '+254',
    [Country.Kiribati]: '+686',
    [Country.Kuwait]: '+965',
    [Country.Kyrgyzstan]: '+996',
    [Country.Laos]: '+856',
    [Country.Latvia]: '+371',
    [Country.Lebanon]: '+961',
    [Country.Lesotho]: '+266',
    [Country.Liberia]: '+231',
    [Country.Libya]: '+218',
    [Country.Liechtenstein]: '+423',
    [Country.Lithuania]: '+370',
    [Country.Luxembourg]: '+352',
    [Country.Madagascar]: '+261',
    [Country.Malawi]: '+265',
    [Country.Malaysia]: '+60',
    [Country.Maldives]: '+960',
    [Country.Mali]: '+223',
    [Country.Malta]: '+356',
    [Country.MarshallIslands]: '+692',
    [Country.Mauritania]: '+222',
    [Country.Mauritius]: '+230',
    [Country.Mexico]: '+52',
    [Country.Moldova]: '+373',
    [Country.Monaco]: '+377',
    [Country.Mongolia]: '+976',
    [Country.Montenegro]: '+382',
    [Country.Morocco]: '+212',
    [Country.Mozambique]: '+258',
    [Country.Myanmar]: '+95',
    [Country.Namibia]: '+264',
    [Country.Nauru]: '+674',
    [Country.Nepal]: '+977',
    [Country.Netherlands]: '+31',
    [Country.NewZealand]: '+64',
    [Country.Nicaragua]: '+505',
    [Country.Niger]: '+227',
    [Country.Nigeria]: '+234',
    [Country.NorthKorea]: '+850',
    [Country.NorthMacedonia]: '+389',
    [Country.Norway]: '+47',
    [Country.Oman]: '+968',
    [Country.Pakistan]: '+92',
    [Country.Palau]: '+680',
    [Country.Panama]: '+507',
    [Country.PapuaNewGuinea]: '+675',
    [Country.Paraguay]: '+595',
    [Country.Peru]: '+51',
    [Country.Philippines]: '+63',
    [Country.Poland]: '+48',
    [Country.Portugal]: '+351',
    [Country.Qatar]: '+974',
    [Country.Romania]: '+40',
    [Country.Russia]: '+7',
    [Country.Rwanda]: '+250',
    [Country.SaintKittsAndNevis]: '+1869',
    [Country.SaintLucia]: '+1758',
    [Country.SaintVincentAndTheGrenadines]: '+1784',
    [Country.Samoa]: '+685',
    [Country.SanMarino]: '+378',
    [Country.SaoTomeAndPrincipe]: '+239',
    [Country.SaudiArabia]: '+966',
    [Country.Senegal]: '+221',
    [Country.Serbia]: '+381',
    [Country.Seychelles]: '+248',
    [Country.SierraLeone]: '+232',
    [Country.Singapore]: '+65',
    [Country.Slovakia]: '+421',
    [Country.Slovenia]: '+386',
    [Country.SolomonIslands]: '+677',
    [Country.Somalia]: '+252',
    [Country.SouthAfrica]: '+27',
    [Country.SouthKorea]: '+82',
    [Country.SouthSudan]: '+211',
    [Country.Spain]: '+34',
    [Country.SriLanka]: '+94',
    [Country.Sudan]: '+249',
    [Country.Suriname]: '+597',
    [Country.Swaziland]: '+268',
    [Country.Sweden]: '+46',
    [Country.Switzerland]: '+41',
    [Country.Taiwan]: '+886',
    [Country.Tajikistan]: '+992',
    [Country.Tanzania]: '+255',
    [Country.Thailand]: '+66',
    [Country.TimorLeste]: '+670',
    [Country.Togo]: '+228',
    [Country.Tonga]: '+676',
    [Country.TrinidadAndTobago]: '+1868',
    [Country.Tunisia]: '+216',
    [Country.Turkey]: '+90',
    [Country.Turkmenistan]: '+993',
    [Country.Tuvalu]: '+688',
    [Country.Uganda]: '+256',
    [Country.Ukraine]: '+380',
    [Country.UnitedArabEmirates]: '+971',
    [Country.UnitedKingdom]: '+44',
    [Country.Guernsey]: '+441481',
    [Country.UnitedStates]: '+1',
    [Country.Uruguay]: '+598',
    [Country.Uzbekistan]: '+998',
    [Country.Vanuatu]: '+678',
    [Country.Venezuela]: '+58',
    [Country.Vietnam]: '+84',
    [Country.Yemen]: '+967',
    [Country.Zambia]: '+260',
    [Country.Zimbabwe]: '+263',
};

const dialCodesToCountry = invert(countryDialCodes);
const dialCodesSortedByLength = orderBy(values(countryDialCodes), dialCode => dialCode.length, 'desc');

export const findCountryByPhoneNumber = (phoneNumber: string | undefined | null): Country | null => {
    if (phoneNumber === undefined || phoneNumber === null) {
        return null;
    }
    const dialCode = dialCodesSortedByLength.find(dc => phoneNumber.startsWith(dc));

    if (!dialCode) {
        return null;
    }

    const countryCode = dialCodesToCountry[dialCode] as Country;

    if (countryCode === Country.Spain || countryCode === Country.CanaryIslands) {
        const areaCode = phoneNumber.substring(3, 6);
        return CANARY_ISLANDS_AREA_CODES.includes(areaCode) ? Country.CanaryIslands : Country.Spain;
    }

    if (countryCode === Country.UnitedStates || countryCode === Country.Canada) {
        const areaCode = phoneNumber.substring(2, 5);
        return CANADA_AREA_CODES.includes(areaCode) ? Country.Canada : Country.UnitedStates;
    }

    return countryCode;
};

const formatPhoneNumber = (inputValue: string | null) => {
    if (!inputValue) {
        return null;
    }

    // Remove all non-digit characters from the input value, except first + if it exists
    const cleaned = cleanPhoneNumber(inputValue);

    // Format the cleaned input value
    if (cleaned.length < 4) {
        return cleaned;
    }
    if (cleaned.length < 7) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    }

    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};

const formatWithCountryDialCode = (countryCode: Country | null, nationalNumber: string | null) => {
    if (!nationalNumber) {
        return countryCode ? countryDialCodes[countryCode] : '';
    }

    const formattedNationalNumber = formatPhoneNumber(nationalNumber);

    return countryCode
        ? `${countryDialCodes[countryCode]} ${formattedNationalNumber}`
        : `${formattedNationalNumber}`;
};

interface ParsedPhoneNumber {
    country: Country | null;
    /** +19051234123 */
    phoneNumber: Phone | null;
    /** 9051234123 */
    nationalNumber: string | null;
    /** (905) 123-4123 */
    formattedPhoneNumber: string | null;
    /** +1 (905) 123-4123 */
    formattedNationalNumber: string | null;
}

export const parsePhoneNumber = (value: string | null, initialCountry?: Country): ParsedPhoneNumber => {
    if (!value) {
        return {
            country: initialCountry || null,
            phoneNumber: null,
            nationalNumber: '',
            formattedNationalNumber: '',
            formattedPhoneNumber: initialCountry ? countryDialCodes[initialCountry] : '',
        };
    }

    const cleanedPhoneNumber = normalizePhoneNumber(value);
    const matchedCountry = findCountryByPhoneNumber(cleanedPhoneNumber);

    const countryCode = matchedCountry || null;
    const countryDialCode = countryCode ? countryDialCodes[countryCode] : '';
    const nationalNumber = countryCode
        ? cleanedPhoneNumber.slice(countryDialCode.length)
        : cleanedPhoneNumber;
    const formattedNationalNumber = formatPhoneNumber(nationalNumber);
    const formattedPhoneNumber = formatWithCountryDialCode(countryCode, nationalNumber);

    return {
        country: countryCode,
        phoneNumber: cleanedPhoneNumber,
        nationalNumber,
        formattedNationalNumber,
        formattedPhoneNumber,
    };
};

export const normalizePhoneNumber = (value: string): Phone => {
    const phoneNumber = cleanPhoneNumber(value);

    if (phoneNumber.startsWith('00')) {
        return `+${phoneNumber.slice(2)}` as Phone;
    }

    return phoneNumber as Phone;
};

const cleanPhoneNumber = (phoneNumber: string | null) =>
    phoneNumber ? phoneNumber.replace(/(?!^\+)\D+/g, '') : '';

export const isPhoneNumberWithIncorrectCountryCode = (phoneNumber: string | undefined | null) => {
    return findCountryByPhoneNumber(phoneNumber) === null;
};

export const isPhoneNumberTooShort = (phoneNumber: string | undefined | null) => {
    return !!phoneNumber && phoneNumber.length < 7;
};
